<template>
  <div class="container" v-if="isShow==false" :class="isShow==false?'showstyle':''">
    <Header :class="isShow==false?'dynamic':''"/>
    <div class="firstbox">
      <div class="poimg">
        <img src="../../../assets/text.png" alt="">
      </div>
      <el-carousel trigger="click" height="100vh" arrow="never">
        <el-carousel-item v-for="item in 1" :key="item">
          <img width="100%" height="100%" src="../../../assets/indexlunbo.png" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
  <div class="containertwo" v-else>
    <Header :class="isShow==true?'dynamic':''"/>
    <div class="content" :class="isShow==true?'showstyletwo':''">
      <div class="firstbox w">
        <div class="firstboxtitle">
          <div class="tab">
            <div class="tabitem" :class="changetab===0?'tabitem1':''" @click="changtab(0)">新闻资讯</div>
            <div>/</div>
            <div class="tabitem" :class="changetab===1?'tabitem1':''" @click="changtab(1)">通知公告</div>
          </div>
        </div>
        <div class="titleright" @click="jumptoneslist">MORE+</div>
      </div>
      <div class="itemallbox w">
        <div class="itembox" v-for="(item,index) in news" :key="index">
          <div class="itemimg"><img src="../../../assets/newsbg.png" alt=""></div>
          <div class="itemcontent">
            <div class="istop">
              <img src="../../../assets/istop.png" alt="" width="44px" height="36px">
            </div>
            <div class="databox">
              <div class="data">{{item.data}}</div>
              <div class="datatime">{{item.time}}</div>
            </div>
            <div class="itemtitle">
              {{item.title}}
            </div>
            <div class="tongzhi">
              {{item.content}}
            </div>
            <div class="hidtext" @click="jumptodetail">进一步了解 ></div>
          </div>
        </div>
      </div>
      <div class="secondbox">
        <div class="twoimg w">
          <div class="leftimg">
            <div class="bigment">
              <div class="btn">查询信息</div>
            </div>
          </div>
          <div class="rightimg">
            <div class="bigment">
              <div class="btn">查询信息</div>
            </div>
          </div>
        </div>
      </div>
      <div class="thirdbox w">
        <div class="thboxmsg">
          招聘信息
        </div>
        <div class="thboxtitle">
          <div class="threetab">
            <div class="tabbar" :class="active===0?'active':''" @click="changactive(0)">全部</div>
            <div class="tabbar" :class="active===1?'active':''" @click="changactive(1)">校招</div>
            <div class="tabbar" :class="active===2?'active':''" @click="changactive(2)">实习</div>
          </div>
          <div class="titleright">MORE+</div>
        </div>
        <div class="cards" ref="piaoleft">
          <div :class="changeleft==true?'leftpiao':''">
            <div  class="carditem" v-for="(item,index) in position" :key="index">
            <div class="cardtop">
              <div class="cardone">
                <div class="typename">
                  <div class="type" v-if="item.type==0">校招</div>
                  <div class="type1" v-else>实习</div>
                  <div class="title">{{item.title}}</div>
                </div>
                <div class="xinzi">{{item.xinzhi}}</div>
              </div>
              <div class="cardtwo">
                {{item.xueli}} &nbsp;| &nbsp; {{item.zhuanye}} &nbsp;| &nbsp; {{item.adress}}
              </div>
              <div class="cardthree">
                <div class="tags">五险一金</div>
                <div class="tags">期权</div>
                <div class="tags">节假日福利</div>
              </div>
            </div>
            <div class="cardbtm">
              <div class="cardbtnleft">
                <img :src="item.img" alt="">
              </div>
              <div class="cardbtnright">
                <div class="posname">{{item.name}}</div>
                <div class="posmsg">{{item.num}} &nbsp; | &nbsp; {{item.qiye}}</div>
              </div>
            </div>
          </div>
          </div>

        </div>
      </div>
      <div class="fourbox">
        <div class="boxcontent w">
          <div class="fourboxtitle">
            招聘活动
          </div>
          <div class="zhaopinactive">
            <div ref="scrollview" class="calendar" :class="changebgsan==true?'changbgsan':''">
              <vc-calendar is-expanded title-position="right" :masks="{ title: 'YYYY年 MM月' }" />
            </div>
            <div class="recruit">
              <div class="activetab">
                <div class="tabs" :class="tabs==item.val?'tabs1':''" v-for="(item,index) in tab" :key="index" @click="changetabs(item.val)">
                  {{item.name}}
                </div>
                <div class="more" @click="seeCareerTalkList">MORE+</div>
              </div>
              <div class="activecard">
                <div class="carditems" v-for="(item,index) in doubleselect" :key="index"  @click="seeCareerTalkInfo">
                  <img :src="item.img" alt="">
                  <div class="hovercontent">
                    <div class="hovertitle">{{item.title}}</div>
                    <div class="jubantime">
                      <img src="../../../assets/icon-wenjian.png" alt="">
                      <div class="enddata">
                        举办截止日期：
                      </div>
                      <div class="xianchang">
                        {{item.starttime}}-{{item.endtime}}
                      </div>
                    </div>
                    <div class="seetonum">
                      <img src="../../../assets/icon-eyetou.png" alt="">
                      <div class="enddata">
                        浏览量：
                      </div>
                      <div>{{item.seenum}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fivebox w">
        <div class="fiveboxtitle">
          就业指导
        </div>
        <div class="fiveboxitem" ref="showtbdynamic">
          <div class="itemimg2" :class="showtbdynamic==true?'dong1':''"><img src="../../../assets/zhidao1.png" alt=""></div>
          <div class="itemimg2" :class="showtbdynamic==true?'dong2':''"><img src="../../../assets/zhidao2.png" alt=""></div>
          <div class="itemimg2" :class="showtbdynamic==true?'dong1':''"><img src="../../../assets/zhidao3.png" alt=""></div>
          <div class="itemimg2" :class="showtbdynamic==true?'dong2':''"><img src="../../../assets/zhidao4.png" alt=""></div>
        </div>
      </div>
      <div class="sixbox">
        <div class="sixboxtitle w">
          快捷通道
        </div>
        <div class="btmsel w">
          <el-carousel :interval="4000" type="card" height="398px" arrow="never" @change="changeinter">
            <el-carousel-item v-for="(item,index) in carouselitem" :key="index">
              <div class="carpos" v-if="inter==index">{{item.text}}</div>
              <img width="717px" height="398px" :src="item.img" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <Footer/>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  name: '',
  data () {
    return {
      showtbdynamic: false, // 控制就业指导动画
      inter: 0, // 当前轮播项
      changebgsan: false, // 控制日历动画是否生效
      changeleft: false, // 控制招聘信息动画生效
      isShow: false, // 控制显示第一屏第二屏
      active: 0, // 切换校招实习tab
      changetab: 0, // 切换新闻资讯tab
      tabs: 0, // 切换招聘活动tab
      tab: [
        {
          name: '今日招聘活动',
          val: 0
        },
        {
          name: '空中宣讲会',
          val: 1
        },
        {
          name: '线下宣讲会',
          val: 2
        },
        {
          name: '空中双选会',
          val: 3
        },
        {
          name: '线下双选会',
          val: 4
        }
      ],
      news: [
        {
          title: '关于做好学生离校后就业工作的通知',
          data: '2022-06',
          time: '15',
          content: '疫情期间，为方便毕业生线上办理就业手续和用人单位开展线上招聘，我校就业服务工作采用“线上'
        },
        {
          title: '我校成为首批河南省高等学校科技成果转化和技术转移基地',
          data: '2022-06',
          time: '14',
          content: '近日，河南省教育厅、河南省科技厅、河南省人力资源和社会保障厅、河南省财政厅联合发布《河南省教育厅等四部门关于公布首批河南省高等学校科技成果转化和技术转移基地认定名单的通知》（教科技〔2022〕191号），包括我校在内的10所高校被认定为首批河南省高等学校科技成果转化和技术转移基地。'
        },
        {
          title: '我校召开2022年度校内巡察整改“回头看”工作部署会',
          data: '2022-06',
          time: '13',
          content: '6月13日，我校召开2022年度校内巡察整改“回头看”工作部署会议，学校巡察工作领导小组组长、纪委书记、监察专员张文强出席会议并讲话，巡察整改“回头看”督导组全体成员参加会议。'
        }
      ],
      position: [
        {
          title: 'Java开发工程师',
          type: '0',
          xinzhi: '15-18K',
          xueli: '本科',
          zhuanye: '计算机',
          adress: '北京市海淀区',
          name: '百度',
          num: '10000 人以上',
          qiye: '私营企业',
          img: require('@/assets/logo_baidu.jpg')
        },
        {
          title: '前端开发工程师',
          type: '1',
          xinzhi: '8-10K',
          xueli: '专科',
          zhuanye: '计算机',
          adress: '北京市海淀区',
          name: '百度',
          num: '10000 人以上',
          qiye: '私营企业',
          img: require('@/assets/logo_baidu.jpg')
        },
        {
          title: '网页设计师',
          type: '1',
          xinzhi: '8-10K',
          xueli: '本科',
          zhuanye: '计算机',
          adress: '北京市海淀区',
          name: '百度',
          num: '10000 人以上',
          qiye: '私营企业',
          img: require('@/assets/logo_baidu.jpg')
        },
        {
          title: '架构师',
          type: '0',
          xinzhi: '20-25K',
          xueli: '硕士',
          zhuanye: '计算机',
          adress: '北京市朝阳区',
          name: '百度',
          num: '10000 人以上',
          qiye: '私营企业',
          img: require('@/assets/logo_baidu.jpg')
        }
      ],
      doubleselect: [
        {
          img: require('@/assets/thum/1.jpg'),
          title: '颜值经济新选择，医见倾心驭未来”2022年医美头部企业校园空中双选会',
          starttime: '2022/02/21 12:00',
          endtime: '2022/04/30 16:00',
          seenum: '7814'
        },
        {
          img: require('@/assets/thum/2.jpg'),
          title: '千企万人 暖风行动”——河北农业大学',
          starttime: '2022/05/21 11:00',
          endtime: '2022/07/30 11:00',
          seenum: '5232'
        },
        {
          img: require('@/assets/thum/3.jpg'),
          title: '用友伙伴，千人计划”，2022年春季线上校园招聘会（第二场）',
          starttime: '2022/06/21 10:00',
          endtime: '2022/10/30 12:00',
          seenum: '1025'
        },
        {
          img: require('@/assets/thum/4.jpg'),
          title: '用友伙伴，千人计划”，2022年春季线上校园招聘会',
          starttime: '2022/03/21 10:00',
          endtime: '2022/05/30 14:00',
          seenum: '5254'
        }
      ],
      carouselitem: [
        {
          img: 'https://t7.baidu.com/it/u=1611369752,691482068&fm=193&f=GIF',
          text: '万事俱备，只等你'
        },
        {
          img: 'https://t7.baidu.com/it/u=3749468628,2799667871&fm=193&f=GIF',
          text: '美丽北京'
        },
        {
          img: 'https://t7.baidu.com/it/u=2100461953,4165915283&fm=193&f=GIF',
          text: '美丽上海'
        }
      ]
    }
  },
  components: {
    Header, Footer
  },
  methods: {
    // 查看宣讲会详情
    seeCareerTalkInfo () {
      this.$router.push('/mutualSelectionInfo')
    },
    // 查看宣讲会列表
    seeCareerTalkList () {
      this.$router.push('/mutualSelectionList')
    },
    // 跳转到新闻列表
    jumptoneslist () {
      this.$router.push({
        path: '/newslist'
      })
    },
    // 跳转到新闻详情
    jumptodetail () {
      this.$router.push({
        path: '/newsdetails'
      })
    },
    // 切换轮播触发
    changeinter (val) {
      this.inter = val
    },
    // 切换新闻资讯触发
    changtab (val) {
      this.changetab = val
    },
    // 切换招聘信息触发
    changactive (val) {
      this.active = val
    },
    // 切换招聘活动触发
    changetabs (val) {
      this.tabs = val
    },
    handleScroll (e) {
      const direction = e.deltaY > 0 ? 'down' : 'up' // 该语句可以用来判断滚轮是向上滑动还是向下
      if (direction === 'down') {
        this.isShow = true
      } else if (direction === 'up') {
        // console.log('up')
      }
      // if (document.getElementsByTagName('li').length === 1) { // 此处决定无论一次滚轮滚动的距离是多少，此事件
      //   // 都得等上次滚动结束，才会执行本次
      //   this.isShow = false
      //   setTimeout(() => {
      //     this.isShow = true
      //     ++this.nowIndex
      //     if (this.nowIndex === 3) {
      //       this.nowIndex = 0
      //     }
      //   }, 10)
      // }
    },
    windowScroll () {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop === 0) {
        this.isShow = false
      }
      if (this.$refs.scrollview.getBoundingClientRect().top < 550) {
        this.changebgsan = true
      } else if (this.$refs.scrollview.getBoundingClientRect().top < -500) {
        this.changebgsan = false
      }
      if (this.$refs.piaoleft.getBoundingClientRect().top < 860) {
        this.changeleft = true
      }
      if (this.$refs.showtbdynamic.getBoundingClientRect().top < 900) {
        this.showtbdynamic = true
      }
    }
  },
  mounted () {
    // 监听鼠标滚动事件
    window.addEventListener('mousewheel', this.handleScroll, false)
    // 监听滚动条事件
    window.addEventListener('scroll', this.windowScroll)
  }
}
</script>

<style scoped lang="less">
@keyframes top{
  0%{
    transform: translateY(-100px);
  }
  100%{
    transform: translateY(0px);
  }
}
@keyframes bottom{
  0%{
    transform: translateY(-139px);
  }
  100%{
    transform: translateY(0px);
  }
}
@keyframes animate{
  0%{
    transform: translateY(-100%);
  }
  100%{
    transform: translateY(0);
  }
}
@keyframes animatetwo{
  0%{
    transform: translateY(100vh);
  }
  100%{
    transform: translateY(0);
  }
}
@keyframes changebgcolor{
  0%{
    background-color: #D9E1EF;
  }
  100%{
    background-color: rgba(63, 158, 255, 0.5);
  }
}
@keyframes changhei{
  0%{
    background-color: rgba(63, 158, 255, 0.5);
    color: #fff;
    height: 101px;
    padding-top: 23px;
  }
  100%{
    background-color: #fff;
    color: #333333;
    height: 83px;
    padding-top: 14px;
  }
}
@keyframes showtext{
  0%{
    display: none;
  }
  100%{
    display: block;
  }
}
@keyframes changeimg{
  0%{
    background-image: url(../../../assets/shengyuanbg.png);
  }
  100%{
    background-image: url(../../../assets/hoverbg.png);
  }
}
@keyframes changeimgtwo{
  0%{
    background-image: url(../../../assets/youjibg.png);
  }
  100%{
    background-image: url(../../../assets/hoverhand.png);
  }
}
@keyframes showment{
  0%{
    display: none;
    background-color: none;
    color: rgba(51, 51, 51, 0.5);
  }
  100%{
    display:block;
    background-color: rgba(51, 51, 51, 0.5);
    color: #fff;
  }
}
@keyframes showmenttwo{
  0%{
    display: none;
    background-color: none;
    color: rgba(51, 51, 51, 0.5);
  }
  100%{
    display:block;
    background-color: #004D9B;
    color: #fff;
  }
}
@keyframes shadow{
  0%{

  }
  100%{
    box-shadow: 0px 20px 20px 0px rgba(1, 79, 160, 0.2);
  }
}
@keyframes danru{
  0%{
    transform: translateX(-358px);
  }
  100%{
    transform: translateX(0px);
  }
}
@keyframes formright{
  0%{
    transform: translateX(1200px);
  }
  100%{
    transform: translateX(0px);
  }
}
@keyframes formleft{
  0%{
    transform: translateX(-1200px);
  }
  100%{
    transform: translateX(0px);
  }
}
@keyframes changebg{
  0%{
    background-size: 100% 200%;
  }
  100%{
    background-size: 100% 100%;
  }
}
@keyframes left{
  0%{
    transform: translateX(-1200px);
  }
  100%{
    transform: translateX(0px);
  }
}
@keyframes changetop{
  0%{
    transform: translateY(-292px);
  }
  100%{
    transform: translateY(0px);
  }
}
@keyframes changebottom{
  0%{
    transform: translateY(292px);
  }
  100%{
    transform: translateY(0px);
  }
}
.showstyle{
  animation-name:animate;/*动画的名字*/
  animation-duration: 1s;/*动画持续*/
}
.showstyletwo{
  animation-name:animatetwo;/*动画的名字*/
  animation-duration: 1s;/*动画持续*/
}
.container{
  min-width: 1200px;
    .dynamic{
      animation-name:top;/*动画的名字*/
      animation-duration: 1s;/*动画持续*/
      animation-delay: 1s;
      animation-fill-mode: backwards;/*规定动画结束后状态，保持 forwards 回到起始 backwards*/
    }
  .firstbox{
    position: relative;
    // z-index: 50;
    .poimg{
      display: none;
      position: absolute;
      z-index: 50;
      bottom: 93px;
      left: 46%;
      img{
        width: 147px;
        height: 30px;
      }
    }
    /deep/.el-carousel__indicators{
      bottom: 23px;
    }
    /deep/.el-carousel__button{
      height: 12px;
      width: 53px;
      border: 3px solid #fff;
      border-radius: 15.5px;
      background: none;
      opacity: 1;
    }
    /deep/.is-active button{
      background: #fff;
    }
  }
}
.containertwo{
  min-width: 1200px;
  .dynamic{
    animation-name:bottom;/*动画的名字*/
    animation-duration: 1.5s;/*动画持续*/
    animation-fill-mode: backwards;/*规定动画结束后状态，保持 forwards 回到起始 backwards*/
  }
  .firstbox{
    position: relative;
    margin-top: 200px;
    .titleright{
      position: absolute;
      right: 0;
      bottom: 0;
      font-size: 16px;
      color: #004D9B;
      font-weight: bold;
      cursor: pointer;
    }
    .firstboxtitle{
      width: 638px;
      height: 55px;
      margin: 0 auto;
      text-align: center;
      background-image: url(../../../assets/bulletin.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      .tab{
        display: flex;
        justify-content: center;
        font-size: 32px;
        font-weight: bold;
        color: #999;
        padding-top: 13px;
        .tabitem1{
          color: #3A485C;
        }
        .tabitem{
          cursor: pointer;
          // &:hover{
          //   color: #3A485C;
          // }
        }
      }
    }
  }
  .itemallbox{
    margin-top: 49px;
    display: flex;
    animation-name:formright;/*动画的名字*/
    animation-duration: 2s;/*动画持续*/
    animation-fill-mode: backwards;/*规定动画结束后状态，保持 forwards 回到起始 backwards*/
    overflow: hidden;
    .itembox{
      width: 384px;
      height: 309px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
      position: relative;
      margin-right: 24px;
      &:last-child{
        margin-right: 0px;
      }
      .itemimg{
        width: 384px;
        height: 309px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .itemcontent{
        width: 384px;
        height: 309px;
        position: absolute;
        background-color: #D9E1EF;
        top: 0;
        padding: 0 40px;
        box-sizing: border-box;
        .istop{
          position: absolute;
          right: 0;
        }
        .databox{
          background-color: rgba(63, 158, 255, 1);
          width: 77px;
          height: 101px;
          text-align: center;
          color: #fff;
          padding-top: 23px;
          box-sizing: border-box;
          .data{
            font-size: 12px;
          }
          .datatime{
            font-size: 40px;
            font-weight: bold;
          }
        }
        .hidtext{
          margin-top: 30px;
          color: #fff;
          font-size: 14px;
          display: none;
        }
        .itemtitle{
          color: #222;
          font-size: 20px;
          font-weight: bold;
          line-height: 32px;
          margin-top: 35px;
        }
        .tongzhi{
          color: #666;
          font-size: 14px;
          line-height: 22px;
          margin-top: 12px;
          overflow: hidden;
          display: -webkit-box;
          white-space: normal !important;
          word-wrap: break-word;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        &:hover{
          animation-name:changebgcolor;/*动画的名字*/
          animation-duration: 1s;/*动画持续*/
          background-color: rgba(63, 158, 255, 0.5);
          .databox{
            background-color: #fff;
            color: #333333;
            animation-name:changhei;/*动画的名字*/
            animation-duration: 1s;/*动画持续*/
            height: 83px;
            padding-top: 14px;
          }
          .itemtitle{
            color: #fff;
          }
          .tongzhi{
            color: #fff;
          }
          .hidtext{
            animation-name:showtext;/*动画的名字*/
            animation-duration: 1s;/*动画持续*/
            display: block;
            cursor: pointer;
          }
        }
      }
    }
  }
  .secondbox{
    width: 100%;
    height: 379px;
    margin-top: 87px;
    background-image: url(../../../assets/blueyun.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding-top: 76px;
    .twoimg{
        display: flex;
        animation-name:formleft;/*动画的名字*/
        animation-duration: 2s;/*动画持续*/
        animation-fill-mode: backwards;/*规定动画结束后状态，保持 forwards 回到起始 backwards*/
        overflow: hidden;
      .leftimg{
        width: 590px;
        height: 240px;
        margin-right: 20px;
        background-image: url(../../../assets/shengyuanbg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .bigment{
          display: none;
          width: 100%;
          height: 100%;
          background-color: rgba(51, 51, 51, 0.5);
          padding-top: 90px;
          box-sizing: border-box;
          border-radius: 6px;
          .btn{
            font-size: 20px;
            display: none;
            width: 140px;
            height: 60px;
            color: #fff;
            text-align: center;
            line-height: 60px;
            border-radius: 6px;
            margin:0 auto;
            cursor: pointer;
            //margin-top: 80px;
          }
        }
        &:hover{
          animation-name:changeimg;/*动画的名字*/
          animation-duration: 2s;/*动画持续*/
          animation-fill-mode: forwards;
          background-image: url(../../../assets/hoverbg.png);
          .bigment{
            animation-name:showment;/*动画的名字*/
            animation-duration: 2s;/*动画持续*/
            display: block;
            animation-fill-mode: forwards;
            .btn{
              animation-name:showmenttwo;/*动画的名字*/
              animation-duration: 2s;/*动画持续*/
              animation-fill-mode: forwards;
              display: block;
              cursor: pointer;
            }
          }
        }
      }
      .rightimg{
        width: 590px;
        height: 240px;
        background-image: url(../../../assets/youjibg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .bigment{
          display: none;
          width: 100%;
          height: 100%;
          background-color: rgba(51, 51, 51, 0.5);
          padding-top: 90px;
          box-sizing: border-box;
          border-radius: 6px;
          .btn{
            font-size: 20px;
            display: none;
            width: 140px;
            height: 60px;
            color: #fff;
            text-align: center;
            line-height: 60px;
            border-radius: 6px;
            margin:0 auto;
            cursor: pointer;
            //margin-top: 80px;
          }
        }
        &:hover{
          animation-name:changeimgtwo;/*动画的名字*/
          animation-duration: 2s;/*动画持续*/
          animation-fill-mode: forwards;
          background-image: url(../../../assets/hoverhand.png);
          .bigment{
            animation-name:showment;/*动画的名字*/
            animation-duration: 2s;/*动画持续*/
            display: block;
            animation-fill-mode: forwards;
            .btn{
              animation-name:showmenttwo;/*动画的名字*/
              animation-duration: 2s;/*动画持续*/
              animation-fill-mode: forwards;
              display: block;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .thirdbox{
    margin-top: 104px;
    .thboxmsg{
      width: 1142px;
      height: 55px;
      margin: 0 auto;
      background-image: url(../../../assets/informat.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      text-align: center;
      font-size: 32px;
      font-weight: bold;
      color: #3A485C;
      padding-top: 17px;
      box-sizing: border-box;
    }
    .thboxtitle{
      margin-top: 64px;
      width: 100%;
      height: 70px;
      background-image: url(../../../assets/informatebg.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;
      padding-top: 15px;
      box-sizing: border-box;
      .titleright{
        position: absolute;
        right: 10px;
        bottom: 23px;
        font-size: 16px;
        color: #004D9B;
        font-weight: bold;
        cursor: pointer;
      }
      .threetab{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .tabbar{
          width: 128px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          color: #004D9B;
          cursor: pointer;
        }
        .active{
          background-color: #014FA0;
          border-radius: 20px;
          color: #fff;
        }
      }
    }
    .cards{
      margin-top: 43px;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      .leftpiao{
        animation-name:left;/*动画的名字*/
        animation-duration: 1.5s;/*动画持续*/
        animation-fill-mode: forwards;
        .carditem{
          display: inline-block;
        }
      }
      .carditem{
        display: none;
        width: 384px;
        height: 209px;
        border: 1px solid #DDDDDD;
        border-radius: 4px;
        margin-right: 24px;
        margin-bottom: 24px;
        box-sizing: border-box;
        &:hover{
          cursor: pointer;
          animation-name:shadow;/*动画的名字*/
          animation-duration: 1s;/*动画持续*/
          animation-fill-mode: forwards;
          //box-shadow: 0px 20px 20px 0px rgba(1, 79, 160, 0.2);
        }
        &:nth-child(3n){
          margin-right: 0px;
        }
        .cardtop{
          padding: 14px 19px 16px 26px;
          box-sizing: border-box;
          height: 139px;
          .cardone{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .typename{
              display: flex;
              align-items: center;
              .type{
                border-radius: 2px;
                width: 56px;
                height: 27px;
                color: #fff;
                font-size: 12px;
                background-color: #F58718;
                text-align: center;
                line-height: 27px;
              }
              .type1{
                border-radius: 2px;
                width: 56px;
                height: 27px;
                color: #fff;
                font-size: 12px;
                background-color: #1890F5;
                text-align: center;
                line-height: 27px;
              }
              .title{
                margin-left: 10px;
                font-weight: bold;
                color: #3A485C;
                font-size: 20px;
                width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            .xinzi{
              color: #FF6436;
              font-size: 18px;
            }
          }
          .cardtwo{
            color: #666666;
            font-size: 14px;
            margin-top: 12px;
          }
          .cardthree{
            display: flex;
            margin-top: 17px;
            .tags{
              height: 27px;
              line-height: 27px;
              padding: 0px 15px;
              border-radius: 2px;
              color: #999999;
              font-size: 12px;
              border: 1px solid rgba(1, 79, 160, 0.2);
              background-color: rgba(1, 79, 160, 0.04);
              margin-right: 10px;
              &:last-child{
                margin-right: 0px;
              }
            }
          }
        }
        .cardbtm{
          height: 70px;
          width: 100%;
          background-image: url(../../../assets/cardbg.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          padding: 0 0 0 26px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          .cardbtnleft{
            width: 44px;
            height: 44px;
            margin-right: 10px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .cardbtnright{
            .posname{
              color: #666;
              font-size: 14px;
              margin-bottom: 11px;
            }
            .posmsg{
              color: #999;
              font-size: 12px;
            }
          }
        }

      }
    }
  }
  .fourbox{
    margin-top: 64px;
    height: 793px;
    width: 100%;
    background-image: url(../../../assets/bigbgimg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-top: 104px;
    box-sizing: border-box;
    .boxcontent{
      .fourboxtitle{
        margin: 0 auto;
        width: 871px;
        height: 56px;
        background-image: url(../../../assets/titlebg.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        color: #3A485C;
        padding-top: 17px;
        box-sizing: border-box;
      }
      .zhaopinactive{
        margin-top: 63px;
        display: flex;
        .changbgsan{
          background-image: url(../../../assets/rilibgsan.png);
          background-repeat: no-repeat;
          animation-name:changebg;/*动画的名字*/
          animation-duration: 1.5s;/*动画持续*/
          animation-fill-mode: forwards;
        }
        .calendar{
          width: 419px;
          height: 481px;
          background-color: #fff;
          //background-size: 100% 100%;
          //background-position: center center;
          .vc-container{
            border: none;
            background: none;
            /deep/.vc-title{
              color: #fff;
              font-size: 16px;
              padding-right: 60px;
            }
            /deep/.vc-header{
              height: 70px;
              width: 100%;
              background-image: url(../../../assets/riliheader.png);
              background-repeat: no-repeat;
              background-size: 100% 100%;
              padding: 0;
            }
            /deep/.vc-weeks{
              padding: 10px;
            }
            /deep/.vc-weekday{
              height: 40px;
              line-height: 30px;
              font-size: 18px;
              color: #666666;
              font-weight: bold;
              &:nth-child(6){
                color: #f00000;
              }
              &:nth-child(7){
                color: #f00000;
              }
            }
            /deep/.vc-day{
              min-height: 40px;
              font-size: 16px;
              color: #333333;
            }
            /deep/.title-right{
                justify-content: space-between !important;
              }
            /deep/.vc-day-content:focus{
              background-color: #f00000;
              color: #fff;
            }
            /deep/.vc-day-content:hover{
              background-color: #f00000;
              color: #fff;
            }
            /deep/.vc-arrows-container{
              top: 11px;
              left: 223px;
              width: 43%;
              .vc-svg-icon path{
                fill: #fff;
              }
            }
          }
        }
        .recruit{
          width: 775px;
          height: 481px;
          background-image: url(../../../assets/zhoamuling.png);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          padding: 15px 20px;
          box-sizing: border-box;
          .activetab{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .tabs{
              width: 134px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              color: #004D9B;
              font-weight: bold;
              font-size: 20px;
              cursor: pointer;
            }
            .more{
              color: #004D9B;
              font-weight: bold;
              font-size: 16px;
              cursor: pointer;
            }
            .tabs1{
              color: #fff;
              background-color: #004D9B;
              border-radius: 20px;
            }
          }
          .activecard{
            display: flex;
            flex-wrap: wrap;
            .carditems{
              width: 358px;
              height: 187px;
              margin-top: 16px;
              margin-right: 16px;
              position: relative;
              overflow: hidden;
              &:nth-child(2n){
                margin-right: 0;
              }
              img{
                width: 100%;
                height: 100%;
              }
              .hovercontent{
                display: none;
                overflow: hidden;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 77, 155, 0.8);
                position: absolute;
                top: 0;
                padding: 38px 17px 0;
                box-sizing: border-box;
                .hovertitle{
                  color: #fff;
                  font-size: 20px;
                  font-weight: bold;
                  overflow: hidden;
                  display: -webkit-box;
                  white-space: normal !important;
                  word-wrap: break-word;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
                .jubantime{
                  display: flex;
                  font-size: 14px;
                  color: #fff;
                  margin-top: 21px;
                  img{
                    margin-top: 4px;
                    margin-right: 6px;
                    width: 13px;
                    height: 12px;
                  }
                  .xianchang{
                    width: 205px;
                  }
                }
                .seetonum{
                  display: flex;
                  align-items: center;
                  font-size: 14px;
                  color: #fff;
                  margin-top: 4px;
                  img{
                    margin-top: 4px;
                    margin-right: 6px;
                    width: 15px;
                    height: 12px;
                  }
                }
              }
              &:hover{
                cursor: pointer;
                .hovercontent{
                  display: block;
                  animation-name:danru;/*动画的名字*/
                  animation-duration: 1.5s;/*动画持续*/
                  animation-fill-mode: forwards;
                }
              }
            }
          }
        }
      }
    }
  }
  .fivebox{
    margin-top: 104px;
    .fiveboxtitle{
        margin: 0 auto;
        width: 740px;
        height: 55px;
        background-image: url(../../../assets/guidance.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        color: #3A485C;
        padding-top: 17px;
        box-sizing: border-box;
    }
    .fiveboxitem{
      display: flex;
      margin-top: 74px;
      overflow: hidden;
      .itemimg2{
        width: 283px;
        height: 292px;
        margin-right: 23px;
        cursor: pointer;
        img{
          width: 100%;
          height: 100%;
        }
        &:last-child{
          margin-right: 0px;
        }
      }
      .dong1{
        animation-name:changetop;/*动画的名字*/
        animation-duration: 1.5s;/*动画持续*/
        animation-fill-mode: forwards;
      }
      .dong2{
        animation-name:changebottom;/*动画的名字*/
        animation-duration: 1.5s;/*动画持续*/
        animation-fill-mode: forwards;
      }
    }
  }
  .sixbox{
    margin-top: -122px;
    padding-top: 226px;
    box-sizing: border-box;
    height: 819px;
    width: 100%;
    background-image: url(../../../assets/bigbottombg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .sixboxtitle{
        margin: 0 auto;
        width: 573px;
        height: 55px;
        background-image: url(../../../assets/passage.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        color: #3A485C;
        padding-top: 17px;
        box-sizing: border-box;
    }
    .btmsel{
      margin-top: 64px;
      height: 398px;
      /deep/.el-carousel__indicators--outside{
        display: none;
      }
      .el-carousel__item{
        .carpos{
          background: linear-gradient(180deg, rgba(51, 51, 51, 0.3), rgba(51, 51, 51, 1) 100%);
          color: #fff;
          font-size: 20px;
          font-weight: bold;
          position: absolute;
          //background-color: pink;
          width: 100%;
          height: 50px;
          line-height: 50px;
          bottom: 0px;
          text-align: center;
        }
      }
    }
  }
}
</style>
